<template>
  <div class="user-content">
    <div class="bold">
      <h2>我的参与</h2>
    </div>
    <div class="contain">
      <div class="sideBar">
        <ul>
          <li @click="selected = 'events'" :class="{ on: selected == 'events' }">赛事活动</li>
          <!-- <li
              @click="selected = 'activity'"
              :class="{ on: selected == 'activity' }"
            >
              体育活动
            </li> -->
          <li @click="selected = 'train'" :class="{ on: selected == 'train' }">体育培训</li>
          <li @click="selected = 'course'" :class="{ on: selected == 'course' }">教学活动</li>
        </ul>
      </div>
      <!-- 体育赛事 -->
      <el-scrollbar class="u-ST sidebar-wrapper">
        <div class="contain-cont" v-if="selected == 'events'">
          <my-events-list></my-events-list>
        </div>
        <div class="contain-cont" v-if="selected == 'activity'">
          <my-activity-list></my-activity-list>
        </div>
        <!-- 培训 -->
        <div class="contain-cont" v-if="selected == 'train'">
          <my-participation-list></my-participation-list>
        </div>
        <!-- 教学活动 -->
        <div class="contain-cont" v-if="selected == 'course'">
          <my-coures-list></my-coures-list>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import myParticipationList from "@/pages/component/myParticipationList";
import myEventsList from "@/pages/component/myEventsList";
import myActivityList from "@/pages/component/myEventsList";
import myCouresList from "@/pages/component/myCouresList";
export default {
  name: "user-participation",
  components: {
    myEventsList,
    myParticipationList,
    myActivityList,
    myCouresList,
  },
  data() {
    return {
      selected: this.$route.query.type || "events",
    };
  },

  // props: {
  //   type: String,
  // },
  mounted() {
    // this.selected = this.type;
    // console.log("selected", this.type);
    // this.selected
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.user-content {
  .bold {
    height: 54px;
    h2 {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
      border-bottom: 1px solid #ececec;
    }
  }
  .contain {
    display: flex;
    margin-top: 22px;
    .sideBar {
      width: 160px;
      border-right: 1px solid #ececec;
      min-height: 90vh;
      ul {
        padding-top: 6px;
        li {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: #555555;
          cursor: pointer;

          &.on {
            color: #1e50ae;
          }
        }
      }
    }

    .orgApply-form {
      padding-left: 20px;
      padding-top: 20px;
      font-size: 14px;
      color: #555555;

      .bold {
        h2 {
          font-size: 16px;
          font-weight: bold;
          padding-bottom: 12px;
          margin-bottom: 30px;
          border-bottom: 1px solid #ececec;
        }
      }

      ::v-deep .el-form-item__label {
        font-size: 14px;
        color: #555555;
      }

      ::v-deep .el-input {
        width: 555px;
      }

      ::v-deep.avatar-uploader .el-upload {
        //border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 82px;
        height: 82px;
      }

      ::v-deep.avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }

      ::v-deep.el-upload__tip {
        color: #999999;
      }
      .avatar {
        width: 82px;
        height: 82px;
        object-fit: cover;
        border-radius: 50%;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 82px;
        height: 82px;
        //line-height: 360px;
        //text-align: center;
        fill: #c2c2c2;

        :hover {
          fill: #409eff;
        }
      }

      .avatar-uploader-icon :hover {
        fill: #409eff;
      }

      .large {
        width: 400px;
        object-fit: contain;
      }

      .applyOrgBtn {
        ::v-deep .el-button {
          width: 80px;
          height: 34px;
          line-height: 9px;
        }

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
          //margin-top: 22px;
        }
      }

      ::v-deep.el-upload__tip {
        margin-top: 0;
      }

      .el-checkbox-group {
        width: 580px;
      }

      .remark,
      .download {
        color: #409eff;
        cursor: pointer;
      }
    }

    .security-form {
      padding-left: 29px;
      font-size: 14px;
      color: #333;
      &.readonly {
        .el-input {
          width: 300px;
          /deep/ .el-input__inner {
            background: #f0f0f0;
            border: 1px solid #e4e4e4;
            border-radius: 4px;
            color: #999999;
          }
        }
        .btnbor {
          border: 0;
          color: #1e50ae;
          font-size: 14px;
          font-weight: 400;
          &:hover {
            color: #1e50ae;
            background: transparent;
          }
        }
      }
    }
  }
}
/deep/ .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
/deep/ .is-horizontal {
  display: none;
}

</style>
