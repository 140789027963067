<template>
  <div class="myParticipationList">
    <!-- 查询 -->

    <el-form :inline="true" :model="queryParams" ref="queryParams" label-width="50px" class="search-bar">
      <el-form-item label="名称:" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入名称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="submitForm('queryParams')">搜索</el-button>
        <el-button @click="resetForm('queryParams')">重置</el-button>
      </el-form-item>
    </el-form>
<!--    <el-scrollbar class="u-STB">-->
    <!-- list -->
      <div class="in_competition">
        <div class="in_competition-item" v-for="item in eventsList" :key="item.id" @click="goDetail(item.id)">
          <el-image class="in_competition-img" :src="loadUrl(item.image)" fit="cover"></el-image>
          <div class="in_competition-text">
            <h3>
              <el-tag v-if="item.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
              <el-tag v-if="item.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
              <el-tag v-if="item.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
              <el-tag v-if="item.status == 9" effect="dark" size="mini">比赛中</el-tag>
              <el-tag v-if="item.status == 10" type="info" effect="dark" size="mini">比赛已结束</el-tag>
              {{ item.name }}
            </h3>
            <span class="date-text">比赛时间： {{ formatDate(item.gameStartTime) }} 至 {{ formatDate(item.gameEndTime) }}</span>
            <span class="date-text">比赛地点： {{ item.province }} {{item.city}} {{item.area}} {{item.address}}</span>
          </div>
        </div>
        <el-empty v-if="eventsList && eventsList.length == 0" description="暂无参与体育赛事"></el-empty>
      </div>
      <!-- page -->
      <el-pagination
        v-if="total > 0"
        class="page-bar"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
<!--    </el-scrollbar>-->
  </div>
</template>

<script>
import {competitionSignPersonnelUserApplyList} from "../../api/events";

export default {
  name: "my-Participation-List",
  components: {},
  data() {
    return {
      eventsList: [],
      total: 0,
      queryParams: {
        current: 1,
        size: 8,
        name: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      self.$set(self.queryParams, "current", this.queryParams.current++);
      competitionSignPersonnelUserApplyList(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.eventsList = res.data.records;
          this.total = res.data.total;
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    submitForm() {
      this.queryParams.current = 1;
      this.init();
    },
    resetForm() {
      this.queryParams.name = ''
      this.queryParams.current = 1;
      this.init();
    },
    handleSizeChange(val) {
      this.$set(this.queryParams, "size", val);
      this.queryParams.current = 1;
      this.init();
    },
    handleCurrentChange(val) {
      this.queryParams.current = val;
      this.init();
    },
    goDetail(id) {
      this.$router.push(`/events-detail/${id}`);
      // this.$router.push('train-detail')
    },
  },
};
</script>

<style lang="scss" scoped>
.myParticipationList {
  padding-left: 10px;
  .search-bar {
    padding-top: 0;
    margin-left: 60px;
  }
   .in_competition {
    .in_competition-item {
      margin-left: 65px;
      margin-right: 0;
      overflow-x: hidden;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  /deep/ .el-scrollbar__wrap{
    overflow-x: hidden;
  }
}
.page-bar{
  width: auto;
  margin-right: 20px;
}
</style>
