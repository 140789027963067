<template>
  <div class="participation-content">
    <!-- 培训查询 -->

    <el-form :inline="true" :model="queryParams" ref="queryParams" label-width="50px" class="participation-search-bar">
      <el-form-item label="课程:" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入课程名称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 赛事活动 -->
    <div class="in_participation">
      <div class="wrapper" v-for="(item,index) in courseList" :key="index" :class="{one:size===1,other:size===1.25}">
        <div :class="{one:size===1,other:size===1.25}" class="in_participation-item" @click="goDetail(item.applyPrderId,item.hasExam)">
          <el-image class="in_participation-img" :src="loadUrl(item.coverImg)" fit="cover"></el-image>
          <div class="in_participation-text">
            <h3>{{ item.title }}</h3>
            <div class="text">
              <span class="date-text">{{ item.courseLength }} | {{ item.teachingMethod == 1 ? '线上视频' : item.teachingMethod == 2 ? '线上资料' : '线下' }}</span>
              <span class="date-text">报名时间：{{ formatDateH(item.enrollStartTime) }} 至{{ formatDateH(item.enrollEndTime) }}</span>
              <span class="date-text">培训时间：{{ formatDateH(item.startTime) }} 至{{ formatDateH(item.endTime) }}</span>
              <span class="date-text" v-if="item.isExam==1">考试时间：{{ item.examTimes?item.examTimes:'未发布' }}</span>
              <span class="date-text " v-else>考试时间：<span class="red">不需要考试</span></span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- page -->
    <el-pagination v-if="total > 0" class="page-bar" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryParams.current" :page-size="queryParams.size" layout="total,  prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
import {newCourseExamApplyCourse} from "../../api/course";

export default {
  components: {},
  data() {
    return {
      courseList: [],
      total: 0,
      queryParams: {
        current: 1,
        size: 8,
        name: '',
      },
      size: 1,
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.size = window.devicePixelRatio
      // this.queryParams.size = this.size === 1.25 ? 8 : 10
      const self = this
      self.$store.commit('showLoading')
      // self.$set(self.queryParams, 'current', pageNum++)

      newCourseExamApplyCourse(self.queryParams).then((res) => {
        if (res.code === 200) {
          this.courseList = res.data.records
          this.total = res.data.total
        } else {
          self.$message(res.msg)
        }
        self.$store.commit('hideLoading')
      })
    },

    search() {
      this.init()
    },
    resetForm() {
      this.queryParams = {
        current: 1,
        size: 8,
        name: '',
      }
      this.init()
    },

    //分页
    handleSizeChange(val) {
      this.$set(this.queryParams, 'size', val)
      this.init()
    },
    handleCurrentChange(val) {
      this.queryParams.current = val
      this.init()
    },
    //跳转
    goDetail(applyPrderId,hasExam) {
      this.$router.push({
        path: '/myCourse-detail',
        query: {applyPrderId: applyPrderId,hasExam:hasExam}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.participation-content {
  padding: 22px 16px;

  .participation-search-bar {
    margin-left: 65px;
    font-size: 14px;
    color: #555;
  }
}

.in_participation {
  width: 100%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;

  .wrapper {
    height: 340px;
    margin-bottom: 16px;
    margin-left: 65px;
    //margin-right: 13px;
    &.one{
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
    &.other{
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }}

  .in_participation-item {
    //box-sizing: border-box;
    position: relative;
    width: 290px;
    height: 270px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(154, 154, 154, 0.14);

    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 6px;

    &.other {
      width: 262px;
    }

    &:hover {
      height: 340px;
      transform: translateY(-60px);
      transition: all 0.5s;
      box-shadow: 0px 0px 16px 0px rgba(76, 76, 76, 0.33);
    }

    .in_participation-img {
      width: 100%;
      height: 162px;
      transition: all 0.3s;
    }

    .in_participation-text {
      padding: 10px;

      h3 {
        font-size: 16px;
        //padding-bottom: 10px;
        //max-height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

      }

      .text {
        padding-top: 13px;
      }

      .date-text {
        display: block;
        padding-top: 10px;
        font-size: 12px;
        color: #666666;

        .red {
          color: #FF3F35;
        }
      }

      .price {
        display: flex;
        align-items: center;
        padding-top: 16px;
        font-size: 22px;
        font-weight: 400;
        color: #ff1d00;
      }
    }
  }

.page-bar{
  width: auto;
  margin-right: 20px;
}

</style>


